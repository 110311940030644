<template>
  <div id="custom-banner" class="carousel slide banner" data-bs-ride="carousel">
    <div v-if="banners?.length > 0" class="carousel-inner">
      <div
        v-for="(banner, index) in banners"
        :key="index"
        :class="index == 0 ? 'active' : ''"
        class="carousel-item"
      >
        <nuxt-img
          v-if="$isDesktop() == true"
          class="d-block w-100"
          :class="props.class"
          :src="banner.imagem_site"
          :alt="banner.imagem_site"
        />
        <nuxt-img
          v-if="$isDesktop() == false"
          class="d-block w-100"
          :class="props.class"
          :src="banner.imagem_mobile"
          :alt="banner.imagem_mobile"
        />
      </div>
    </div>
    <div v-else-if="imagem" class="carousel-inner banner">
      <div class="carousel-item active">
        <CustomPicture
          class="d-block"
          width="100%"
          height="auto"
          :class="props.class"
          :imagem="imagem"
        />
        <div class="carousel-caption d-none d-md-block">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  class: String,
  banners: Array,
  imagem: String,
});
</script>

<script>
export default {
  name: "CustomBanner",
};
</script>
